main {
  position: relative;
  height: calc(100vh - 74px);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

@media (width > 768px) and (height < 600px) {
  main {
    height: 100%;
    padding: 25px 0;
  }
}

@media (width < 1024px) {
  main {
    height: 100%;
    padding: 25px 0;
  }
}

@media (width <= 768px) {
  main {
    padding: 15px;
  }
}

.inbound {
  width: 75%;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 15px;
  margin: 0 auto;
  z-index: 0;
}

@media (width < 1024px) {
  .inbound {
    width: 90%;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
  }
}

@media (width <= 768px) {
  .inbound {
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.scanner {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (width < 1024px) {
  .scanner {
    width: 100%;
    align-items: center;
  }
}

form {
  max-width: 525px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form .tip {
  width: 100%;
  max-width: 525px;
  margin-top: 5px;
  padding: 1px;
  border: 1px dashed #007bff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  text-align: center;
}

@media (width < 1280px) {
  form .tip {
    font-size: 0.8rem;
  }
}

@media (width < 1024px) {
  form .tip {
    font-size: 0.9rem;
  }
}

form .icon {
  margin: 0 auto;
  display: block;
  min-width: 47.5%;
}

form .icon.main {
  width: 100%;
  margin-bottom: 15px;
}

form .icon.barcode {
  font-size: 23px;
}

.input-group-md {
  width: 100%;
}

.inbound-list {
  width: 60%;
  max-width: 525px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}

@media (width < 1024px) {
  .inbound-list {
    width: 100%;
    height: 400px;
    margin: 0 auto;
  }
}

.inbound-list .title {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.inbound-list .title h3 {
  font-size: 1.3rem;
  display: contents;
  align-items: center;
}

@media (width < 1024px) {
  .inbound-list .title h3 {
    font-size: 1.1rem;
  }
}

.inbound-list .title span {
  padding: 4px 10px;
  border-radius: 5px;
  color: white;
  background-color: #007bff;
  font-size: 1.1rem;
  font-weight: 900;
  text-align: center;
}

.inbound-list ul {
  max-height: 450px;
  padding: 5px;
  border-radius: 5px;
  margin: 0 0 auto auto;
  overflow-y: auto;
}

.inbound-list ul li {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

@media (width < 1024px) {
  .inbound-list ul li {
    font-size: 0.9rem;
  }
}

@media (width < 340px) {
  .inbound-list ul li {
    gap: 5px;
    font-size: 0.8rem;
  }
}

.inbound-list ul li span:first-child {
  font-weight: 700;
  color: #504d4d;
  text-align: right;
}

.inbound-list ul li span:last-child {
  font-style: italic;
  color: #504d4d;
}

.inbound-list ul li .actions {
  display: flex;
  gap: 10px;
}

.inbound-list ul li .actions .btn {
  width: 20px;
  margin-top: -2px;
  margin-left: auto;
  padding: 0;
  border: none;
  font-size: 1rem;
  font-style: italic;
  text-align: right;
}

.inbound-list ul li .actions .btn.print:hover {
  background-color: transparent;
  color: #007bff;
}

.inbound-list ul li .actions .btn.clear:hover {
  background-color: transparent;
  color: #000;
}

.inbound-list ul li span .btn.clear {
  width: 20px;
  margin-top: -2px;
  margin-left: auto;
  padding: 0;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  font-style: italic;
  text-align: right;
}

.inbound-list ul li.empty {
  color: #504d4d;
  font-style: italic;
}

.inbound-list .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin: 0 0 0 auto;
}

.inbound-list .btn {
  margin-top: 2px;
  margin-left: auto;
  padding: 2px 5px;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  font-style: italic;
  text-align: right;
}

.inbound-list .btn.print-all:hover {
  background-color: transparent;
  color: #007bff;
}

.inbound-list .btn.clear:hover {
  background-color: transparent;
  color: #000;
}

.notifications {
  max-width: 400px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
}

.notifications .notification {
  padding: 5px 25px 5px 5px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  animation: slideOutRight 0.5s forwards 5s;
}

.notifications .notification .title {
  font-size: 1rem;
  font-weight: 500;
}

.notifications .notification .title span {
  font-size: 1rem;
  font-weight: 400;
}

.notifications .notification .message {
  font-size: 0.9rem;
}

.notifications .notification .message span {
  font-weight: 700;
}

.notifications .notification .clear {
  position: absolute;
  top: 5px;
  right: 0;
  border: none;
  display: flex;
  align-items: center;
}

.notifications .notification .clear:hover {
  background-color: transparent;
  color: #000;
}

.notifications .notification .icon {
  color: gray;
  font-size: 1.5rem;
}

.notifications .notification .icon.success {
  color: green;
}

.notifications .notification .icon.info {
  color: #007bff;
}

.notifications .notification .icon.error {
  color: red;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(115%);
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(#ddd, 0.3);
  background-color: #ddd;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #007bff83;
  -webkit-box-shadow: inset 0 0 6px rgba(#007bff, 0.5);
}
