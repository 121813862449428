.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.modal-channel {
    overflow-y: scroll;
    height: auto;
}

.modal-unassignmentReason {
    overflow-y: scroll;
    height: 50vh;
    text-align: left;
}

.modal-unassignmentReason .form-check-inline {
    margin: 0 10px 10px 15px;
}

.modal-stocks {
    overflow-y: scroll;
    height: 70vh;
}

.form-control-checkbox {
    width: 1em;
}

.modal-body p {
    white-space: pre-wrap;
}

.btn-assign-mas {
    margin-right: -50px;
}

.container .card .card-body {
    padding: inherit;
    font-size: 12px;
}

.container .card .card-body table td p {
    margin-bottom: 0.4em !important;
}

#loading-mask {
    position: absolute;
    z-index: 500;
}

#loading-mask .loader {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    background: rgba(255, 255, 255, .85);
    color: #000;
    z-index: 1000;
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2em;
    opacity: 0.8;
}

#loading-mask .loader img {
    margin-bottom: 10px
}

#loading-mask .spinner {
    margin-bottom: 1.5em;
    display: block;
    width: 2em;
    height: 2em;
    box-shadow: 0 0 0 .15em rgba(24, 144, 255, 1);
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    position: relative
}

#loading-mask-part .loader {
    padding: 30px;
    background: rgba(255, 255, 255, .85);
    color: #000;
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2em
}

#loading-mask-part .loader img {
    margin-bottom: 10px
}

#loading-mask-part .spinner {
    margin-bottom: 1.5em;
    display: block;
    width: 2em;
    height: 2em;
    box-shadow: 0 0 0 .15em rgba(24, 144, 255, 1);
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    position: relative
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        border-radius: 50%
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        border-radius: 50%
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

input:focus, input.form-control:focus, select:focus, textarea:focus, button:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: inherit;
}

#print {
    float: right;
    margin-right: 10px;
}

.spinner-border {
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;
    color: rgba(24, 144, 255, 1);
}

.stockDepo {
    font-size: 13px;
}

.check-alert {
    font-size: 13px;
}

.dropdown-menu-custom {
    z-index: 1000;
    display: block;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
}

input#inpsearch::placeholder {
    color: #b7b6b6;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .card-body {
        padding: 1em;
    }
    .sku-row {
        display: none;
    }
    .price-row {
        display: none;
    }
    .qty-row {
        display: none;
    }
}

#checkStockOnline {
    margin-top: .41rem!important;
}

.alert-warning-custom {
    display: inline-block!important;
    padding: 5px 20px!important;
    margin-top: 5px;
}